// IMPORTS
import { useEffect, useState, createContext } from 'react';
import { Toolbar, CssBaseline } from '@mui/material';
import { Routes, Route, Navigate, useLocation, Outlet } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import jwtDecode from 'jwt-decode';
import './Main.css';

// COMPONENTS
import Sidebar from '../OraNextGen/sidebar/Sidebar';
import Header from '../OraNextGen/header/Header';

// LOGIC
import useToken from './useToken';
import socket from '../../context/socket';

//  LOGIN
import Login from '../OraNextGen/general/login/Login';

// ONBOARDING
import OnboardingUI from './Onboarding/OnboardingUI';

// GENERAL ROUTER
import GeneralRouter from '../OraNextGen/routers/GeneralRouter';

// DEPARTMENT ROUTERS
import AdminRouter from '../OraNextGen/routers/AdminRouter';
import PartsRouter from '../OraNextGen/routers/PartsRouter';
import ServiceRouter from '../OraNextGen/routers/ServiceRouter';
import VehicleRouter from '../OraNextGen/routers/VehicleRouter';
import OnlineRouter from '../OraNextGen/routers/OnlineRouter';

// CUSTOMER ESIGN ROUTER
import EsignRouter from '../OraNextGen/routers/EsignRouter';

// DOCUMENTATION ROUTER
import DocumentationRouter from '../OraNextGen/routers/DocumentationRouter';

// DEALER SIGN CONTRACT PAGE
import DealerSignContract from '../OraNextGen/vehicle/signContract/DealerSignContract';

// DEALER SIGN VENDOR STATEMENT PAGE
import DealerTradeDocManager from '../OraNextGen/vehicle/signVendorStatement/DealerTradeDocManager';

import { darkTheme, theme } from './theme';

import GetInitialAppSetupData from '../OraNextGen/global/databaseLogic/GetInitialAppSetupData';
import LoadingBackdrop from '../OraNextGen/global/LoadingBackdrop';

export const PermissionsContext = createContext([]);
export const SiteContext = createContext({});
export const UserContext = createContext({});
export const AllSitesContext = createContext([]);

const GlobalContextProvider = ({
    children,
    allSites,
    siteData,
    permissions,
    currentUser,
    theme
}) => {
    return (
        <AllSitesContext.Provider value={allSites}>
            <SiteContext.Provider value={siteData}>
                <PermissionsContext.Provider value={permissions}>
                    <UserContext.Provider value={currentUser}>
                        <ThemeProvider theme={theme}>
                            <CssBaseline />
                            {children}
                        </ThemeProvider>
                    </UserContext.Provider>
                </PermissionsContext.Provider>
            </SiteContext.Provider>
        </AllSitesContext.Provider>
    );
};

export default function MainComponent() {
    const { token, setToken } = useToken();

    const [backdropOpen, setBackdropOpen] = useState(false);
    const [loggedIn, setLoggedIn] = useState(true);
    const [loading, setLoading] = useState(true);

    const [initialAppData, setInitialAppData] = useState({
        allSites: [],
        permissions: [],
        currentUser: {},
        currentUserSettings: {},
        siteData: { departments: [] },
        currentTheme: theme
    });

    let location = useLocation();

    const isCustomerEsignPage = location.pathname.includes('/esign/');

    useEffect(() => {
        if (isCustomerEsignPage) return setLoading(false);

        const fetchInitialAppSetupData = async () => {
            // if the initialAppData has already been set, don't fetch it again
            if (initialAppData.allSites.length > 0) return;

            const data = await GetInitialAppSetupData({
                SiteId: localStorage.getItem('SiteId')
            });

            const isNotLoginPage =
                window.location.pathname.split('/')[1] !== 'login';

            setInitialAppData({
                allSites: data.allSites,
                permissions: data.permissions,
                currentUser: isNotLoginPage ? data.currentUser : {},
                currentUserSettings: isNotLoginPage
                    ? data.currentUserSettings
                    : {},
                siteData: data.site,
                currentTheme: data.currentUserSettings.darkMode
                    ? darkTheme
                    : theme
            });

            setLoading(false);
        };

        let decoded;
        try {
            if (token) {
                decoded = jwtDecode(token);
            }
        } catch (error) {
            decoded = false;
        }

        if (decoded && decoded.exp > Date.now() / 1000 && decoded?.id != null) {
            socket.emit('login', [decoded.id, '/']);
            fetchInitialAppSetupData();
        } else {
            setLoggedIn(false);
            setLoading(false);
            localStorage.setItem('SiteId', null);
            localStorage.setItem('token', null);
        }
        // eslint-disable-next-line
    }, [token, location.pathname]);

    useEffect(() => {
        // If there is no SiteId in local storage: user isn't logged in
        // Or if is on esign page
        // No need to track where he is with the socket
        if (
            localStorage.getItem('SiteId') !== 'null' &&
            !location.pathname.includes('/esign/')
        ) {
            socket.emit('location', location.pathname);
        } else if (
            localStorage.getItem('SiteId') == null &&
            !location.pathname.includes('/esign/')
        ) {
            localStorage.setItem(
                'SiteId',
                initialAppData.currentUser.SiteId.toString()
            );
        }
        // eslint-disable-next-line
    }, [location]);

    const showHeaderSideBar =
        window.location.pathname !== '/login' &&
        window.location.pathname !== '/onboarding' &&
        window.location.pathname.includes('/esign/') === false;

    function ProtectedRoute() {
        return loggedIn ? <Outlet /> : <Navigate to="/login" />;
    }

    // if data not loaded yet, return backdrop
    if (loading) {
        return <LoadingBackdrop />;
    }

    return (
        <GlobalContextProvider
            theme={initialAppData.currentTheme}
            currentUser={initialAppData.currentUser}
            siteData={initialAppData.siteData}
            permissions={initialAppData.permissions}
            allSites={initialAppData.allSites}
        >
            <div id="main">
                {showHeaderSideBar ? (
                    <>
                        <Header
                            showUpdates={initialAppData.currentUser.needRefresh}
                            setBackdropOpen={setBackdropOpen}
                        />
                        <Sidebar />
                    </>
                ) : null}
                <main className="content">
                    {showHeaderSideBar ? <Toolbar /> : null}
                    <div className="main-content">
                        <Routes>
                            {/* customer esign router */}
                            <Route
                                path="/esign/*"
                                element={
                                    <EsignRouter
                                        token={token}
                                        setToken={setToken}
                                    />
                                }
                            />

                            {/* PUBLIC ROUTES */}
                            <Route
                                path="/login"
                                element={<Login setToken={setToken} />}
                            />
                            <Route
                                path="/onboarding"
                                element={
                                    <ThemeProvider theme={theme}>
                                        <OnboardingUI />
                                    </ThemeProvider>
                                }
                            />

                            {/* PROTECTED ROUTES */}
                            <Route element={<ProtectedRoute />}>
                                <Route
                                    path="/docs/vehicle-contract/:id"
                                    element={<DealerSignContract />}
                                />
                                <Route
                                    path="/docs/vendor-statement/:id"
                                    element={<DealerTradeDocManager />}
                                />
                                {/* DEPARTMENT ROUTERS */}
                                <Route
                                    path="/admin/*"
                                    element={<AdminRouter />}
                                />
                                <Route
                                    path="/inventory/*"
                                    element={<PartsRouter />}
                                />
                                <Route
                                    path="/online/*"
                                    element={<OnlineRouter />}
                                />
                                <Route
                                    path="/vehicles/*"
                                    element={<VehicleRouter />}
                                />
                                <Route
                                    path="/service/*"
                                    element={<ServiceRouter />}
                                />
                                {/* DOCUMENTATION ROUTER */}
                                <Route
                                    path="/support/*"
                                    element={<DocumentationRouter />}
                                />
                                <Route path="/*" element={<GeneralRouter />} />
                            </Route>
                        </Routes>
                    </div>
                </main>
                <LoadingBackdrop open={backdropOpen} loadingTheme={theme} />
            </div>
        </GlobalContextProvider>
    );
}
