// IMPORTS
import { lazy } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import RoleCheck from '../global/RoleCheck';
import Loadable from '../../main/Loadable';

// INVOICE
const Invoice = Loadable(lazy(() => import('../admin/invoice/Invoice')));
const ViewRebateInvoice = Loadable(
    lazy(
        () =>
            import(
                '../admin/invoice/components/RebateInvoice/ViewRebateInvoice'
            )
    )
);
const ViewServiceWarrantyInvoice = Loadable(
    lazy(
        () =>
            import(
                '../admin/invoice/components/ServiceWarranty/ViewServiceWarrantyInvoice'
            )
    )
);
const CreditInvoice = Loadable(
    lazy(() => import('../admin/creditInvoice/CreditInvoice'))
);

const ViewTrustReceipt = Loadable(
    lazy(
        () =>
            import('../admin/invoice/components/TrustInvoice/ViewTrustReceipt')
    )
);

// RECONCILIATION
const DebtorReconciliationTable = Loadable(
    lazy(
        () => import('../admin/debtorReconciliation/DebtorReconciliationTable')
    )
);

const RegoReconciliation = Loadable(
    lazy(() => import('../admin/regoReconciliation/RegoReconciliation'))
);

const RebateReconciliation = Loadable(
    lazy(() => import('../admin/rebateReconciliation/RebateReconciliation'))
);

const CreditorReconciliation = Loadable(
    lazy(() => import('../admin/creditorReconciliation/CreditorReconciliation'))
);

const BankReconciliation = Loadable(
    lazy(() => import('../admin/bankReconciliation/BankReconciliation'))
);

//TIMECLOCK
const TimeClockPage = Loadable(
    lazy(() => import('../admin/payroll/TimeClockPage'))
);

// MISC
const ReportingUITS = Loadable(
    lazy(() => import('../admin/reporting/ReportingUI'))
);

const ManualTransaction = Loadable(
    lazy(() => import('../admin/manualTransaction/ManualTransaction'))
);

const GeneralJournalUI = Loadable(
    lazy(() => import('../admin/generalJournal/GeneralJournalUI'))
);

const GenerateInvoice = Loadable(
    lazy(() => import('../admin/generateInvoice/GenerateInvoice'))
);

const ManualInvoiceTable = Loadable(
    lazy(() => import('../admin/generateInvoice/ManualInvoiceTable'))
);

// DASHBOARD
const AdminDashboard = Loadable(
    lazy(() => import('../admin/dashboard/AdminDashboard'))
);

// TABLES
const AccountTable = Loadable(
    lazy(() => import('../admin/account/AccountTable'))
);

const AdminRouter = () => {
    return (
        <Routes>
            <Route path="/" element={<Outlet />}>
                {/* DASHBOARD */}
                <Route
                    path="dashboard"
                    element={
                        <RoleCheck
                            component={<AdminDashboard />}
                            permission={'access_admin_dashboard'}
                            pageError
                        />
                    }
                />

                {/* TABLES */}
                <Route
                    path="accounts"
                    element={
                        <RoleCheck
                            component={<AccountTable />}
                            permission={'access_accounts'}
                            pageError
                        />
                    }
                />

                {/* INVOICE */}
                <Route
                    path="enterInvoice"
                    element={
                        <RoleCheck
                            component={<Invoice />}
                            permission={'access_invoice'}
                            pageError
                        />
                    }
                />

                <Route
                    path={`viewRebateInvoice/:id`}
                    element={<ViewRebateInvoice />}
                />
                <Route
                    path={`viewServiceWarrantyInvoice/:id`}
                    element={<ViewServiceWarrantyInvoice />}
                />
                <Route
                    path={`viewTrustReceipt/:id`}
                    element={<ViewTrustReceipt />}
                />
                <Route path={`creditInvoice/:id`} element={<CreditInvoice />} />
                <Route path={`creditInvoice`} element={<CreditInvoice />} />

                {/* RECONCILIATION */}
                <Route
                    path={`debtorReconciliation`}
                    element={
                        <RoleCheck
                            component={<DebtorReconciliationTable />}
                            permission={'access_debtRec'}
                            pageError
                        />
                    }
                />

                <Route
                    path={`registrationReconciliation`}
                    element={
                        <RoleCheck
                            component={<RegoReconciliation />}
                            permission={'access_regoRec'}
                            pageError
                        />
                    }
                />

                <Route
                    path={`rebateReconciliation`}
                    element={
                        <RoleCheck
                            component={<RebateReconciliation />}
                            permission={'access_rebateRec'}
                            pageError
                        />
                    }
                />
                <Route
                    path={`creditorReconciliation`}
                    element={
                        <RoleCheck
                            component={<CreditorReconciliation />}
                            permission={'access_credRec'}
                            pageError
                        />
                    }
                />

                <Route
                    path={`bankReconciliation`}
                    element={
                        <RoleCheck
                            component={<BankReconciliation />}
                            permission={'access_bankRec'}
                            pageError
                        />
                    }
                />

                {/* TIMECLOCK */}
                <Route
                    path={`timeclock`}
                    element={
                        <RoleCheck
                            component={<TimeClockPage />}
                            permission={'access_timeclock'}
                            pageError
                        />
                    }
                />

                {/* MISC */}
                <Route
                    path={`reporting`}
                    element={
                        <RoleCheck
                            component={<ReportingUITS />}
                            permission={'access_reporting'}
                            pageError
                        />
                    }
                />

                <Route
                    path={`viewManualTransaction/:id`}
                    element={<ManualTransaction />}
                />

                <Route
                    path={`manualTransaction/`}
                    element={<ManualTransaction />}
                />

                <Route
                    path={`generalAccountingEntry/:id`}
                    element={
                        <RoleCheck
                            component={<GeneralJournalUI />}
                            permission={'access_GJE'}
                            pageError
                        />
                    }
                />

                <Route
                    path={`generalAccountingEntry`}
                    element={
                        <RoleCheck
                            component={<GeneralJournalUI />}
                            permission={'access_GJE'}
                            pageError
                        />
                    }
                />
                <Route
                    path={`generateInvoice`}
                    element={
                        <RoleCheck
                            component={<GenerateInvoice />}
                            permission={'generate_Invoice'}
                            pageError
                        />
                    }
                />

                <Route
                    path={`generateInvoiceTable`}
                    element={
                        <RoleCheck
                            component={<ManualInvoiceTable />}
                            permission={'generate_Invoice'}
                            pageError
                        />
                    }
                />
            </Route>
        </Routes>
    );
};

export default AdminRouter;
